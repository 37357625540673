@import '../../styles/variables.scss';

.start-section {
  padding: 2rem 0;
  background-color: $yellow;
  margin-left: -4%;
  .jumbotron {
      background-color: $yellow;
      color: $gray;
      padding: 8rem 0;
      width: 50%;
      h1 {
        font-family: AnonymousPro;
          font-size: calc(100% + 5vw);
      }
      img {
          max-width: 25%;
      }
  }
}



/* Wobble To Top Right */

@-webkit-keyframes hvr-wobble-to-top-right {
  16.65% {
    -webkit-transform: translate(8px, -8px);
    transform: translate(8px, -8px);
  }
  33.3% {
    -webkit-transform: translate(-6px, 6px);
    transform: translate(-6px, 6px);
  }
  49.95% {
    -webkit-transform: translate(4px, -4px);
    transform: translate(4px, -4px);
  }
  66.6% {
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px);
  }
  83.25% {
    -webkit-transform: translate(1px, -1px);
    transform: translate(1px, -1px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@keyframes hvr-wobble-to-top-right {
  16.65% {
    -webkit-transform: translate(8px, -8px);
    transform: translate(8px, -8px);
  }
  33.3% {
    -webkit-transform: translate(-6px, 6px);
    transform: translate(-6px, 6px);
  }
  49.95% {
    -webkit-transform: translate(4px, -4px);
    transform: translate(4px, -4px);
  }
  66.6% {
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px);
  }
  83.25% {
    -webkit-transform: translate(1px, -1px);
    transform: translate(1px, -1px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
.hvr-wobble-to-top-right {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-wobble-to-top-right:hover, .hvr-wobble-to-top-right:focus, .hvr-wobble-to-top-right:active {
  -webkit-animation-name: hvr-wobble-to-top-right;
  animation-name: hvr-wobble-to-top-right;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

/*******************************************************************************/
