@import '../../../styles/variables.scss';

.process-section {
    font-size: 0.75rem;
    .container-fluid {
        width: 90%;
    }
    h2 {
        padding-bottom: 2rem;
    }
    img {
        max-width: 8rem;
    }
    @media (max-width: 600px) {
        img {
            max-width: 6rem;
        }
    }
}