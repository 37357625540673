@media (max-width: 768px) {
  .website-creation-page, .soft-dev-page{
    h1 {font-size: 1.6rem !important;}
    h2 {font-size: 1.4rem !important;}
    h3 {font-size: 1.2rem !important;}
    h4 {font-size: 1rem !important;}
    h5 {font-size: 0.8rem !important;}
    h6 {font-size: 0.8rem !important;}
    body, p {font-size: 0.6rem !important}
  }
}

// Page Transitions
.page {
  position: fixed;
  top: 0; left: 0; right: 0;
  overflow: auto;	
  box-sizing: border-box;	
  height: 100vh;	
}
.page-transition-enter {
  z-index: 10;
  transform: translateX(100%);
  &-active {
    transform: translateX(0);
    transition: transform 500ms ease-in-out;
  }
}
.page-transition-exit {
  z-index: 9;
  opacity: 1;
  &-active {
    opacity: 0.9;
    transition: opacity 500ms ease-in-out;
  }
}
.back-page-transition-enter {
  opacity: 0;
  &-active {
    opacity: 1;
    transition: opacity 500ms ease-in-out;
  }
}
.back-page-transition-exit {
  transform: translateX(0);
  &-active {
    transform: translateX(100%);
    transition: transform 500ms ease-in-out;
  }
}
////////////////////////////////////

.home-page {
  overflow-x: hidden;
}

/* TIM CSS - MAIN PAGE */
.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
