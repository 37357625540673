$paynes-green: #5d727f;
$slate-gray: #647688;
$light-pink: #e5d4d0;
$dark-pink:  #daa0a0;

// NEW COLOR PACK
$yellow: #FFF779;
$teal: #A6CCBA;
$gray: #565F67;


$font-stack: proxima-nova;
$dark-gray: #565F67;
$blue-teal: #A6CCBA;
$lightbulb-yellow: #FFF779;
$daisy-yellow: #ECC944;
$font-stack: AnonymousPro;
