@import '../../styles/variables.scss';

.row.header-segment {
    padding-top: 1rem;
    height: 42rem;

    .type-segment {
      display: inline;
      width: 50%;
      text-align: left;
      .title-text {
        margin-top: 25%;
        margin-left: 5%;
      }
      h1 {
        font-size: 300%;
        color: $dark-gray;
        padding-left: 3%;
        font-family: AnonymousPro;
        letter-spacing: 0.1em;
      }
    }

    .page-segment {
      width: 50%;
      padding-left: 0;
      padding-right: 0;
      .splash-screen
      {
        width:100%;
        text-align: right;
        padding-right: 3%;
      }
    }
  }


  .mock-site-region {
    overflow: hidden;
    height: 100%;
  }

  .row.header-segment-mobile {
    height: 24rem;
    color: $dark-gray;
    .big-title {
        padding-bottom: 3rem;
        font-weight: bold;
        padding-left: 1rem;
    }
  }
