@import '../../styles/variables.scss';

.link-text {
  color: white;
  text-decoration: none;
  &:hover {
    color: white;
    text-decoration: none;
  }
}

%title-font {
  color: white;
}

%center-text {
  text-align: center;
  vertical-align: middle;
}

%panel-region {
  padding: 40% 0%;
}

%menu-alignment-left {
  float: left;
   margin-left: 1%;
}

%menu-alignment-right {
  float: right;
  margin-right: 1%;
}

.splash-title-line-1 {
  @extend %title-font;
  @extend %center-text;
}

.splash-title-line-2 {
  @extend %title-font;
  @extend %center-text;
}

.splash-screen {
  padding-top: 8rem;
  font-style: AnonymousPro;
}

.contact-screen {
  padding: 25% 0%;
  background-color: $lightbulb-yellow;
  h1 {font-size: 300%;}
  .contact-screen-text {
    color: $dark-gray;
    font-family: AnonymousPro;

    @extend %center-text;
    .contact-title-line-2 {
      display: inline;
    }
    .glyphicon-wrapper
    {
      margin-left: 1%;
      display: inline;
      .glyphicon.glyphicon-arrow-right
      {
        color: black;
        -webkit-transform: rotate(320deg);
        -moz-transform:    rotate(320deg);
        -ms-transform:     rotate(320deg);
        -o-transform:      rotate(320deg);
        transform:         rotate(320deg);
      }
    }
  }
}
