@import '../../styles/variables.scss';

%bg-style {
    background-color: $teal;
}

%text-style {
    color: white;
    text-align: center;
}

.contact-us-modal {
    .modal-body {
        @extend %bg-style;
    }

    .modal-header {
        @extend %bg-style;
        @extend %text-style;
        border-bottom: 0 none;
        .modal-title {
            margin: 0 auto;
            h3 {
                font-weight: bold;
            }
            p {
                font-size: .75rem;
            }
        }
    }

    .modal-footer {
        @extend %bg-style;
        @extend %text-style;
        border-top: 0 none;
    }
}