@import '../../../styles/variables.scss';
@import '../body.scss';

.info-region.container-fluid{
  @extend %title-font;
  padding-right: 0px;
  padding-left: 0px;
  .info-row {
    height: 50rem;
    width:100%;
    background-color: $blue-teal;
    vertical-align: middle;
    .info-title-region {
      height: 100%;
      margin-left: 2%;
      width: 38%;
      background-color: $blue-teal;
      background-color: $blue-teal;
      .info-title {
        font-family: AnonymousPro;
        h2 {
          font-size: 300%;
        }
        padding-top: 23rem;
      }
    }
  }

  .info-options-region {
    text-align: right;
    width: 60%;
    padding-right: 3rem;
  }

  .info-row-mobile {
    width:100%;
    background-color: $blue-teal;
    vertical-align: middle;
    padding-bottom: 10rem;
    .info-title-region-mobile
    {
      color: white;
      text-align: center;
      width: 100%;
      .info-title-mobile {
        font-family: AnonymousPro;
        h2 {
          font-size: 300%;
        }
        margin-top: 10rem;
      }
    }

    .info-options-region-mobile
    {
      color: black;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
  }
}


.info-options{
  position: relative;
  top:45%;
  bottom: 50%;
  float:right;
  .option-button {
    font-family: AnonymousPro;
  }
  .option-button:hover {

  }
}

#option-1 {
  margin-top: -3rem;
}

#option-2 {
  margin-top: 8rem;
}


#option-2-mobile {
  margin-top: 3rem;
}

.option-section{
  position: relative;
  font-family: AnonymousPro;
  padding-left: 7rem;

  .sub-text{
    position: absolute;
    top:0;
    opacity: 0;
    animation-delay: 1s;
    animation: hideSubText 0.25s ease-in normal;
    -o-animation: hideSubText 0.25s ease-in normal;
    -moz-animation: hideSubText 0.25s ease-in normal;
    -webkit-animation: hideSubText 0.25s ease-in normal;
    -ms-animation: hideSubText 0.25s ease-in normal;
  }
  .option-button{
    transform: translate(0rem, 0rem);
    animation-delay: 1s;
    animation: moveTextDown 0.25s ease-in normal;
    -o-animation: moveTextDown 0.25s ease-in normal;
    -moz-animation: moveTextDown 0.25s ease-in normal;
    -webkit-animation: moveTextDown 0.25s ease-in normal;
    -ms-animation: moveTextDown 0.25s ease-in normal;
  }
}

.option-section:hover {
  font-family: AnonymousPro;
  .sub-text {
    opacity: 1;
    animation-delay: 0.25s;
    animation: showSubText 0.25s ease-in normal;
    -o-animation: showSubText 0.25s ease-in normal;
    -moz-animation: showSubText 0.25s ease-in normal;
    -webkit-animation: showSubText 0.25s ease-in normal;
    -ms-animation: showSubText 0.25s ease-in normal;
  }

  .option-button {
    transform: translate(0rem, -3rem);
    animation-delay: 0.25s;
    animation: moveTextUp 0.25s ease-in normal;
    -o-animation: moveTextUp 0.25s ease-in normal;
    -moz-animation: moveTextUp 0.25s ease-in normal;
    -webkit-animation: moveTextUp 0.25s ease-in normal;
    -ms-animation: moveTextUp 0.25s ease-in normal;
  }
}

.info-options-mobile {
  padding-top: 3rem;
  .option-section-mobile {
    position: relative;
    font-family: AnonymousPro;
    .sub-text-mobile {
      opacity: 0;
      position: absolute;
    }
  }
}

.option-button-mobile:hover {
  .option-button-icon-mobile {
    margin-left: 2rem;
    transform: translate(-1rem, 0rem);
    animation: hoverArrow 0.25s ease-in normal;
    -o-animation: hoverArrow 0.25s ease-in normal;
    -webkit-animation: hoverArrow 0.25s ease-in normal;
    -moz-animation: hoverArrow 0.25s ease-in normal;
    -ms-animation: hoverArrow 0.25s ease-in normal;
  }
}

.option-button-icon {
  margin-left: 1rem;
}

.option-button-icon-mobile {
  margin-left: 2rem;
  transform: translate(0rem, 0rem);
  animation: releaseArrow 0.25s ease-in normal;
  -o-animation: releaseArrow 0.25s ease-in normal;
  -webkit-animation: releaseArrow 0.25s ease-in normal;
  -moz-animation: releaseArrow 0.25s ease-in normal;
  -ms-animation: hoverArrow 0.25s ease-in normal;
}

@keyframes showSubText {
  0% {opacity: 0;}
  100%{opacity: 1;}
}
@keyframes-o-animation showSubText {
  0% {opacity: 0;}
  100%{opacity: 1;}
}
@keyframes-moz-animation showSubText {
  0% {opacity: 0;}
  100%{opacity: 1;}
}
@keyframes-webkit-animation showSubText {
  0% {opacity: 0;}
  100%{opacity: 1;}
}
@keyframes-ms-animation showSubText {
  0% {opacity: 0;}
  100%{opacity: 1;}
}


@keyframes moveTextUp {
  0%{transform: translate(0rem, 0rem);}
  100%{transform: translate(0rem, -3rem);}
}
@keyframes-o-animation moveTextUp {
  0%{transform: translate(0rem, 0rem);}
  100%{transform: translate(0rem, -3rem);}
}
@keyframes-moz-animation moveTextUp {
  0%{transform: translate(0rem, 0rem);}
  100%{transform: translate(0rem, -3rem);}
}
@keyframes-webkit-animation moveTextUp {
  0%{transform: translate(0rem, 0rem);}
  100%{transform: translate(0rem, -3rem);}
}
@keyframes-ms-animation moveTextUp {
  0%{transform: translate(0rem, 0rem);}
  100%{transform: translate(0rem, -3rem);}
}

@keyframes hideSubText {
  0% {opacity: 1;}
  100%{opacity: 0;}
}
@keyframes-o-animation hideSubText {
  0% {opacity: 1;}
  100%{opacity: 0;}
}
@keyframes-moz-animation hideSubText {
  0% {opacity: 1;}
  100%{opacity: 0;}
}
@keyframes-webkit-animation hideSubText {
  0% {opacity: 1;}
  100%{opacity: 0;}
}
@keyframes-ms-animation hideSubText {
  0% {opacity: 1;}
  100%{opacity: 0;}
}


@keyframes moveTextDown {
  0%{transform: translate(0rem, -3rem);}
  100%{transform: translate(0rem, 0rem);}
}
@keyframes-o-animation moveTextDown {
  0%{transform: translate(0rem, -3rem);}
  100%{transform: translate(0rem, 0rem);}
}
@keyframes-moz-animation moveTextDown {
  0%{transform: translate(0rem, -3rem);}
  100%{transform: translate(0rem, 0rem);}
}
@keyframes-webkit-animation moveTextDown {
  0%{transform: translate(0rem, -3rem);}
  100%{transform: translate(0rem, 0rem);}
}
@keyframes-ms-animation moveTextDown {
  0%{transform: translate(0rem, -3rem);}
  100%{transform: translate(0rem, 0rem);}
}

@keyframes turnGray {
  0%{color: white;}
  100%{color: $dark-gray;}
}
@keyframes-o-animation turnGray {
  0%{color: white;}
  100%{color: $dark-gray;}
}
@keyframes-moz-animation turnGray {
  0%{color: white;}
  100%{color: $dark-gray;}
}
@keyframes-webkit-animation turnGray {
  0%{color: white;}
  100%{color: $dark-gray;}
}
@keyframes-ms-animation turnGray {
  0%{color: white;}
  100%{color: $dark-gray;}
}

@keyframes hoverArrow {
  0%{transform: translate(0rem, 0rem)}
  100%{transform: translate(-1rem, 0rem)}
}
@keyframes-o-animation hoverArrow {
  0%{transform: translate(0rem, 0rem)}
  100%{transform: translate(-1rem, 0rem)}
}
@keyframes-moz-animation hoverArrow {
  0%{transform: translate(0rem, 0rem)}
  100%{transform: translate(-1rem, 0rem)}
}
@keyframes-webkit-animation hoverArrow {
  0%{transform: translate(0rem, 0rem)}
  100%{transform: translate(-1rem, 0rem)}
}
@keyframes-ms-animation hoverArrow {
  0%{transform: translate(0rem, 0rem)}
  100%{transform: translate(-1rem, 0rem)}
}

@keyframes releaseArrow {
  0%{transform: translate(-1rem, 0rem)}
  100%{transform: translate(0rem, 0rem)}
}
@keyframes-o-animation releaseArrow {
  0%{transform: translate(-1rem, 0rem)}
  100%{transform: translate(0rem, 0rem)}
}
@keyframes-moz-animation releaseArrow {
  0%{transform: translate(-1rem, 0rem)}
  100%{transform: translate(0rem, 0rem)}
}
@keyframes-webkit-animation releaseArrow {
  0%{transform: translate(-1rem, 0rem)}
  100%{transform: translate(0rem, 0rem)}
}
@keyframes-ms-animation releaseArrow {
  0%{transform: translate(-1rem, 0rem)}
  100%{transform: translate(0rem, 0rem)}
}
