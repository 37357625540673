@import '../../styles/variables.scss';

.contact-us-form {
    .message {
        height: 10rem;
    }
    .submit-button {
        border: none;
        background-color: $teal;
        width: 25%;
        &:focus {
            background-color: $teal !important;
        }
        img {
            width: 20%;
        }
        @media (max-width: 600px) {
            img {
                display: none;
            }
        }
    }
}

/* Grow */

.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.hvr-grow:hover, .hvr-grow:focus, .hvr-grow:active {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

/**********************************************************************************/