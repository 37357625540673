%move-to-yellow {
  animation: makeYellow 0.25s ease-in normal;
  -moz-animation: makeYellow 0.25s ease-in normal;
  -webkit-animation: makeYellow 0.25s ease-in normal;
  -ms-animation: makeYellow 0.25s ease-in normal;
  -o-animation: makeYellow 0.25s ease-in normal;
}


@keyframes-moz-animation makeYellow {
  0%{color: $dark-gray;}
  100%{color: $daisy-yellow;}
}
@keyframes-webkit-animation makeYellow {
  0%{color: $dark-gray;}
  100%{color: $daisy-yellow;}
}
@keyframes-ms-animation makeYellow {
  0%{color: $dark-gray;}
  100%{color: $daisy-yellow;}
}
@keyframes-o-animation makeYellow {
  0%{color: $dark-gray;}
  100%{color: $daisy-yellow;}
}
@keyframes makeYellow {
  0%{color: $dark-gray;}
  100%{color: $daisy-yellow;}
}
